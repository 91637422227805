import React, { useEffect } from "react";

import { graphql } from "gatsby";
import { getLanguage } from "utils/language";

export const query = graphql`
           query {
               site {
                   siteMetadata {
                       languages {
                           key
                           showLandingPage
                       }
                   }
               }
           }
       `;

const IndexPage = ({ data }) => {
    const language = getLanguage(data.site.siteMetadata.languages.map(lang => lang.key));
    useEffect(() => {
        window.location.replace("/" + language + "/#");
    }, [language]);

    return <div />;
};

export default IndexPage;
